.brandImage {
    height: 350px;
    // min-width: 350px;
	width: 100%;
	max-width: 100%;
	border-radius: 1rem;
	overflow: hidden;

	@media only screen and (max-width: 800px) {
		height: 230px;
	}
}

.brand {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 2rem;
	padding: 8px;
	position: relative;
	flex: 1;

	@media only screen and (max-width: 800px) {
		gap: 1rem;
		padding: 1rem 0;
	}
}
