.section {
	width: 100vw;
	height: fit-content;
	position: relative;
	padding: 75px 50px;
	padding-top: calc(var(--extra-padding) + 100px);
	scroll-margin-top: 60px;


	&:first-child {
		padding-top: calc(var(--extra-padding) + 100px + 45px);
	}
}

.sectionContent {
	max-width: 1200px;
	margin: auto;
	position: unset;
}

@media only screen and (max-width: 800px) {
	.section {
		padding: 3rem 1rem;
		padding-top: calc(var(--extra-padding) + 3rem);

		&:first-child {
			padding-top: calc(var(--extra-padding) + 3rem + 30px);
		}
	}
}
