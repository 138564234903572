.contentWrapper {
	position: relative;
	display: flex;
	flex-direction: row;
	max-width: 1200px;
	margin: 2.5rem auto;
	border-radius: 0.5rem;
	padding: 0;

	&.shadow {
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	}

	&.center {
		text-align: center;
	}

	&.center .textContentWrapper {
		justify-content: center;
	}

	&.left .textContentWrapper {
		justify-content: flex-start;
	}

	&.left .imageContent {
		right: 0;
		left: var(--element-width);
	}

	&.right .textContentWrapper {
		justify-content: flex-end;
	}

	&.right .imageContent {
		right: var(--element-width);
		left: 0;

		img {
			border-radius: .5rem 0 0 .5rem ;
		}
	}

	@media only screen and (max-width: 800px) {
		.textContent {
			padding: 1em 0!important;
		}
	}
}

.textContentWrapper {
	display: flex;
}

.textContent {
	width: var(--element-width);
	padding: 5rem;
}

.imageContent {
	position: absolute;
	top: 0;
	height: 100%;
}

.imageContent > div {
	height: 100%;
	width: 100%;
}

.imageContent img {
	min-height: 250px;
	border-radius: 8px;
	border-radius: 0 .5rem .5rem 0;
}

@media only screen and (max-width: 800px) {
	.contentWrapper {
		flex-direction: column;
		margin: 1rem 0;

		.imageContent {
			position: relative;
		}

		&.left .imageContent {
			left: initial;
		}


		&.right .imageContent img {
			border-radius: .5rem .5rem  0 0;
		}

		img {
			max-height: 200px;
			object-position: center !important;
			border-radius: .5rem .5rem  0 0;
		}
	}

	.textContent {
		width: 100%;
		margin: 1rem;
	}
}
