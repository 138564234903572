.header {
	position: relative;
	height: 90vh;
	max-height: 1000px;
	max-width: 1800px;
    margin: auto;

	@media screen and (max-width: 800px) {
		height: 75vh;
	}

	&:before {
		content: " ";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 20%;
		background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0) 100%);
	}

	h1 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: var(--color-white);
		font-size: var(--text-size-main-title);
		width: 80vw;
		text-align: center;
		font-family: var(--title-font);
	}

	.heroImage {
		height: 100%;
		width: 100%;
	}

	#logo {
		border-radius: 8px;
		position: absolute;
		top: 16px;
		left: 16px;
		width: 200px;
		z-index: 9;
		fill: var(--color-white);
	}
}
