.Button {
	padding: 16px 24px;
	border-radius: 8px;
	text-decoration: none;
	color: var(--color-white);
	min-width: 130px;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	margin-top: 1rem;
}

.Button[data-appearance="primary"] {
	background: var(--color-dark-green);
}

.Button[data-appearance="secondary"] {
	background: var(--color-light-green);
	color: var(--color-black);
}


