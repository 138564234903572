/* CONTACT BALK*/
#contact {
    background: #87A79D;
    color: #fff!important;
    text-align: center;

    h2 {
        color: var(--color-white);
    }
    
    h4 {
        color: var(--color-white);
    }
    
}

.contact-section {
    margin-top: 4rem;
}

.contact-form-wrapper {
    margin-top: 50px;
}

.contact-wrapper {
    justify-content: space-around;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}

.contact-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.contact-item:hover {
    transform: scale(1.1);
}

.contact-item img {
    filter: brightness(0) invert(1);
    width: 10vw;
    height: auto;
    max-width: 150px;
}

a.contact-item {
    color: #fff!important;
    text-decoration: none;
}

.contact-title {
    color: #fff;
}



@media only screen and (max-width: 800px) {
    .contact-wrapper {
        flex-wrap: wrap;
        word-break: break-word;
        margin: 0;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
    
      .contact-icon img {
        width: 75px;
      }
    
      .contact-item p {
        text-decoration: underline;
        font-size: 16px;
        display: none;
      }

      .contact-section {
        margin-top: 2rem;
    }
}

/* FOOTER */
footer {
    background-color:  #fff2ea;
    height: 2rem;
    width: 100vw;
    max-width: 1800px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0 5vw;
}

.hannet {
    color: #555;
    text-align: right;

    p {
        font-size: 0.85rem;
    }
}
