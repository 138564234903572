.titleWrapper {
	position: relative;

	&.left {
		text-align: left;
	}

	&.right {
		text-align: right;
	}

	&.center {
		text-align: center;
	}

	h4 {
		text-transform: uppercase;
	}

	h2 {
		margin-bottom: 24px;
	}
}
