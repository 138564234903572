@import url(./nav.scss);
@import url(./contact.scss);
@import url(./modals.scss);


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	position: relative;
	font-family: var(--text-font);
	line-height: 1.75rem;
}

h1, h2, h3, h4 {
	font-family: var(--title-font);
	color: var(--color-black)
}

h2 {
	font-size: var(--text-size-title);
}

h3 {
	font-size: var(--text-size-subtitle);
	margin-bottom: 0.5rem;
}

h4 {
	color: var(--color-dark-green);
}

p, div {
	font-size: var(--text-size-body);
	font-weight: 100;
}

p.small {
	font-size: 0.75rem;
}

.button {
	padding: 16px 24px;
	border-radius: 8px;
	text-decoration: none;
	color: var(--color-white)!important;
	text-align: center;
	cursor: pointer;
	background: var(--color-dark-green);
	display: inline-block;
	
	&:hover {
		background: var(--color-light-green);
		color: var(--color-dark-green)!important;;
	}
}

.no-padding-top{
	padding-top: 0!important;
}

.padding-top{
	padding-top: 0!important;
}

.margin-top {
	margin-top: 1rem;
}

.margin-top-small {
	margin-top: 0.5rem;
}

.bottom-right {
	object-position: right bottom!important;

	@media only screen and (max-width: 800px) {
		opacity: 0.2;
	}
}

.button-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}