.divider {
	position: absolute;
	bottom: -1px;
	left: 0;
	width: 100vw;
	overflow: hidden;
	line-height: 0;
	z-index: 10;

	&.top {
		bottom: initial;
		top: -1px;
		transform: rotate(180deg);
	}

	svg {
		fill: var(--color-white);
		position: relative;
		display: block;
		width: 100%;
		height: 3rem;
	}
}
