.contentWrapper {
	position: relative;
	display: flex;
	flex-direction: row;
	max-width: 1200px;
	margin: 1rem auto;
	border-radius: 0.5rem;
	padding: 0;

	&.center {
		text-align: center;
	}

	&.center .textContentWrapper {
		justify-content: center;
	}

	&.left .textContentWrapper {
		justify-content: flex-start;
	}


	&.right .textContentWrapper {
		justify-content: flex-end;
	}

	@media only screen and (max-width: 800px) {
		.textContent {
			padding: 1em 0!important;
		}
	}
}

.textContentWrapper {
	display: flex;
}

.textContent {
	width: var(--element-width);
	padding: 2rem;
}


@media only screen and (max-width: 800px) {
	.contentWrapper {
		flex-direction: column;
		margin: 1rem 0;
	}

	.textContent {
		width: 100%;
		margin: 1rem;
	}
}
