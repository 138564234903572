.modal-content-wrapper {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 3rem;
    margin: 2.5rem auto;
	border-radius: 0.5rem;

    .modal-title-wrapper {
        display: flex;
        align-items: center;

        .modal-title {
            margin-left: 15px;
        }

        .modal-icon {
            img {
                width: 50px;
            }
        }
    }

    .behandelingen-wrapper {
        background: #fff;
        z-index: 10;
        margin: 25px 0;

        .behandeling-row {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 20px;
            
            .behandeling-info-wrapper {
                display: flex;
                justify-content: space-between;
            }

            .behandeling-description-wrapper.small {
                display: flex;
                justify-content: space-between;
            }
    
            .behandeling-price {
                text-align: center;
                min-width: fit-content;
            }

            .behandeling-title,
            .behandeling-price {
                color: var(--color-dark-green);
                margin-bottom: 0;
            }

            .behandeling-subtitle {
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .modal-content-wrapper {
        padding: 1rem;

        .behandelingen-wrapper {
            min-width: initial;
            text-align: left;
        }

        .modal-icon {
            display: none;
        }

        .modal-title-wrapper .modal-title {
            margin-left: 0;
        }
    }
}